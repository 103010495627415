import React from 'react'
import CustomLink from './custom-link'
import NextImage from './image'
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { getGradientColors } from '../../utils/colors';

const ArticleCard = ({event, first, last, isFilterSection = false}) => {
    const ref = React.useRef();
    const timeline = React.useRef();
    const showImage = () => {
      // TweenMax.killTweensOf(this.DOM.reveal);
      // TweenMax.killTweensOf(this.DOM.revealInner);
      // TweenMax.killTweensOf(this.DOM.revealImg);
      // TweenMax.killTweensOf(this.DOM.revealDeco);
      const revealDeco = ref.current.querySelector(".hover-reveal__deco")
      const revealInner = ref.current.querySelector(".hover-reveal__inner")
      const revealImg = ref.current.querySelector(".hover-reveal__inner img")
      const wrapper = ref.current.querySelector(".event-card-image-wrapper")
      timeline.current = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: "top 75%",
          // markers:true,
          toggleActions: "restart none none reverse"
        }
      })
      // gsap.fromTo(revealInner, {
      //   y: "100%",
      // },
      // {
      //   opacity:1,
      //   y:0,
      //   scrollTrigger: {
      //     trigger: revealInner,
      //     start: "top 75%",
      //     markers:true,
      //   },
      //   duration: 1.2,
      //   ease:"Power3.easeOut"
      // },"<")

      // timeline.current.from(revealDeco,
      // {
      //   scaleY:0,
      //   duration: 0.3,
      //   ease:"Sine.easeInOut"
      // })

      // timeline.current.set(revealDeco, {
      //   transformOrigin: "50% 0%"
      // })
      
      // timeline.current.to(revealDeco,
      // {
      //   scaleY:0,
      //   duration: 0.5,
      //   ease:"Expo.easeOut"
      // })


      // timeline.current.from(revealInner,
      // {
      //   // y:"20px",
      //   // left:0,
      //   opacity:0,
      //   duration: 0.5,
      //   // delay:0.1,
      //   ease:"Power2.easeOut"
      // },"<")


      // timeline.current.from(revealInner,
      // {
      //   y:"20px",
      //   // left:0,
      //   // opacity:0,
      //   duration: 0.5,
      //   // delay:0.1,
      //   ease:"Sine.easeOut"
      // },"<")



      // SAMPLE CODE
      // this.tl = new TimelineMax({
      //     onStart: () => {
      //         this.DOM.reveal.style.opacity = 1;
      //         TweenMax.set(this.DOM.el, {zIndex: 1000});
      //     }
      // })
      // .add('begin')
      // .set(this.DOM.revealInner, {y: '100%'})
      // .set(this.DOM.revealDeco, {transformOrigin: '50% 100%'})

      // .add(new TweenMax(this.DOM.revealDeco, 0.3, {
      //     ease: Sine.easeInOut,
      //     startAt: {scaleY: 0, scaleX: 10},
      //     scaleY: 1,
      //     scaleX: 1
      // }), 'begin')

      // .set(this.DOM.revealDeco, {transformOrigin: '50% 0%'})

      // .add(new TweenMax(this.DOM.revealDeco, 0.3, {
      //     ease: Expo.easeOut,
      //     scaleY: 0
      // }), 'begin+=0.3')

      // .add(new TweenMax(this.DOM.revealInner, 0.5, {
      //     ease: Expo.easeOut,
      //     startAt: {y: '100%'},
      //     y: '0%'
      // }), 'begin+=0.4')

      // .add(new TweenMax(this.DOM.revealImg, 0.5, {
      //     ease: Expo.easeOut,
      //     startAt: {y: '-100%'},
      //     y: '0%'
      // }), 'begin+=0.4')

      // .add(new TweenMax(this.DOM.revealImg, 0.5, {
      //     ease: Expo.easeOut,
      //     startAt: {y: '100%'},
      //     y: '0%'
      // }), 'begin+=0.4')

      // .add(new TweenMax(this.DOM.reveal, 1.1, {
      //     ease: Expo.easeOut,
      //     startAt: {y: '50%', rotation: 10},
      //     y: '0%',
      //     rotation: 0
      // }), 'begin');

    }
    React.useEffect(()=>{
        const img = ref.current.querySelector(".event-card-image-wrapper")
        const title = ref.current.querySelector("h3")
        const text = ref.current.querySelector("p")

        // showImage()
    
        gsap.fromTo(img, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          scrollTrigger: {
            trigger: ref.current,
            start: "top center",
            // markers:true,
          },
          duration: 1.2,
          ease:"Power3.easeOut"
        },"<")
    
    
        gsap.fromTo(title, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          duration: 1.2,
          delay:0.5,
          scrollTrigger: {
            trigger: ref.current,
            start: "top center",
            // markers:true,
          },
          ease:"Power3.easeOut"
        },"<")
    
    
        gsap.fromTo(text, {
          opacity:0,
          y:"20px"
        },
        {
          opacity: 1,
          y:0,
          duration: 1.2,
          delay:0.7,
          scrollTrigger: {
            trigger: ref.current,
            start: "top center",
            // markers:true,
          },
          ease:"Power3.easeOut"
        },"<")

        return () => {
          if(timeline.current)
            timeline.current.kill()
        }
    },[])
  return (
    <div className='h-full mb-12 md:mb-0 event-card md:px-5' ref={ref}>
      <CustomLink link={{url: `/articles/${event.slug}` }} hasHoverInteraction={true} hovertext={`plus`}>
        <div className="event-card-image-wrapper" style={{position:"relative", borderRadius:"4px", overflow:"hidden"}}>
            {/* <div class="hover-reveal" style={{zIndex:"2"}}> */}
              {/* <div className="hover-reveal__deco" style={{zIndex:"2"}}></div>
              <div className="relative hover-reveal__inner" style={{zIndex:"1", overflow:"hidden"}}>
                  <NextImage media={event.image} style={{position:"absolute", bottom:"0",left:"0", objectFit:"cover", width:"100%", height:"100%", aspectRatio:"420/429"}}/>
              </div> */}
            {/* </div> */}
            <div className="w-full" style={{ overflow:"hidden"}}>
                <div style={{
                    position:"relative",
                    width: "100%",
                    paddingTop: "102%",
                }}>
                    <NextImage media={event.image} style={{position:"absolute",top:"0",left:"0", objectFit:"cover", width:"100%", height:"100%", aspectRatio:"420/429"}}/>
                </div>
            </div>
            <div style={{position:"absolute", top:"0", left:"0", zIndex:"1"}}>
                <div className="px-4 mt-4 mb-4">
                  <div className="flex flex-wrap items-center justify-start">
                      {event.fields.map((field, index) => {
                            let colors = getGradientColors()[field.slug]
                        return (
                          <div key={index} 
                              className="flex flex-wrap items-center justify-center mr-1 text-center md:w-auto text-md md:text-sm button dark outline-cm always-active md:mr-2 " 
                              style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}
                              // onMouseEnter={(e) => { changeColor(index); }} 
                              // onMouseLeave={(e) => { changeColor(-1); }}
                          >{field.tag}</div>
                        )
                      })}
                  </div>
                    {/* {event.fields.map((field, index)=>{
                        return <span className="mr-2" key={index} style={{fontFamily:"Larish", fontWeight:"600", fontSize:"13px", lineHeight:"24px", color:"white", border:"1px solid white", borderRadius:"22px", width:"50px", display:"inline-block", textAlign:"center"}}>{field.tag}</span>
                    })} */}
                </div>
            </div>
        </div>
        <div className='px-0'>
            {/* <p className='p-0 m-0 mt-4 ' style={{fontSize:"10px", color:"#7C7C7C"}}>{moment(event.date,"YYYY-MM-DD").format("DD.MM.YY")}</p> */}
            <h3 className="mt-5 mb-2 text-base font-bold md:mb-2">{event.title}</h3>
            <p className="text-base md:text-base" style={{ maxWidth:"65ch"}}>{event.description}</p>
            {/* <CustomLink link={{url: `/articles/${event.slug}` }}><span className="block link" style={{cursor:"pointer", color:"#7C7C7C", fontSize:"14px", marginTop:"1.5rem"}}>Ver Mais</span></CustomLink> */}
        </div>
      </CustomLink>
    </div>
  )
}

export default ArticleCard