import React from 'react'
import CustomLink from './custom-link'
import NextImage from './image'

const TeamCard = ({event, first, backgroundColor}) => {
  return (
    <div className='event-card md:px-5 mb-12 border-t-0 border-b-0'>
        <div style={{position:"relative"}}>
            <div className="w-full" style={{borderRadius:"5px", overflow:"hidden"}}>
                <div style={{
                    position:"relative",
                    width: "100%",
                    paddingTop: "124%",
                }}>
                    <NextImage media={event.image} style={{position:"absolute",top:"0",left:"0", objectFit:"cover", width:"100%", height:"100%", aspectRatio:"420/520", borderRadius:"5px"}}/>
                </div>
            </div>
            <div className="h-full w-full" style={{position:"absolute", top:"0", left:"0", bottom:"0", background:`${backgroundColor}55`, borderRadius:"5px"}}>
                <div className="w-full h-full p-4 flex flex-col justify-end align-end text-white" style={{background:"#00000033", borderRadius:"5px"}}>
                    <h3 className="mt-4 mb-4" style={{fontSize:"18px", fontWeight:"600"}}>{event.title}</h3>
                    <p style={{fontSize:"16px", fontWeight:"300"}}>{event.description}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TeamCard