import React, {Suspense} from 'react'
import NextImage from '../elements/image'
import { Canvas } from '@react-three/fiber'


import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import LowShaderPlane from '../gradient/LowShaderPlane'
import { Color, Vector2 } from 'three';
import { PageTransitionContext } from '../page-transition';
import {useRouter} from 'next/router';
import { AdaptiveDpr } from '@react-three/drei';
import { getBannerGradientColors, getGradientColors } from '../../utils/colors';

const gradientColors = [
    "#6B514A",
    "#F55C30",
    "#1BBEAF",
    "#D8FFA2"
]

const HeroGradient = ({ data, pageContext, isInitialLoad }) => {
	
    const router = useRouter()
    const { addToTimeline, isLoaded, playAnimation } = React.useContext(PageTransitionContext);
    const [counter, setCounter] = React.useState(0);
    // console.log(data)
    const leftRef = React.useRef();
    const rightRef = React.useRef();
    const heroRef = React.useRef();
    const timeline = React.useRef();
    const colors = getBannerGradientColors();
    // console.log(colors)
    // const colorsStart = data.colors.map((color) => new Color(color.colorStart).convertLinearToSRGB().getHex())
    // const colorsEnd = data.colors.map((color) => new Color(color.colorEnd).convertLinearToSRGB().getHex())
    const colorsStart = colors.map((color) => new Color(color).convertLinearToSRGB().getHex())
    const colorsEnd = colors.map((color) => new Color(color).convertLinearToSRGB().getHex())


    // var mediaQueries = [
    //     // { id: "xs", media: "(max-width: 640px)" },
    //     // { id: "s", media: "(min-width: 640px) and (max-width: 768px)" },
    //     // { id: "md", media: "(min-width: 768px) and (max-width: 1440px)" },
    //     // { id: "lg", media: "(min-width: 1440px) and (max-width: 1660px)" },
    //     // { id: "xl", media: "(min-width: 1660px)" }
    //     { id: "md", media: "(min-width: 768px) },
    //     { id: "lg", media: "(min-width: 1440px) and (max-width: 1660px)" },
    //   ];


    // setTimeout(() => {
    //     ScrollTrigger.refresh();
    //     ScrollTrigger.update();
    //     console.log("scrolltrigger refresh!");
    // }, 5000);

    const onComplete = () => {


        // FIX FOR FIRST LOAD, IDK WHY THE PLAIN REFRESH DOESN'T WORK ____ TODO: LOOK INTO THIS
        if (typeof(Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents'); 
            evt.initUIEvent('resize', true, false, window, 0); 
            window.dispatchEvent(evt);
        }
        ScrollTrigger.refresh();
        ScrollTrigger.update();
        isLoaded = true;
        // console.log("Refreshing triggers")
    }

    // React.useLayoutEffect(()=>{
    //     console.log(gsap.context())
    // },[])

    React.useEffect(()=>{
			gsap.registerPlugin(ScrollTrigger);
			// console.log("testing", playAnimation);
			if(timeline.current){
				timeline.current.kill();
			}
			timeline.current = gsap.timeline()
            const pathArray = router.asPath.split("/");

            ScrollTrigger.matchMedia({

                // large
                "(min-width: 769px)": function() {
                    timeline.current.fromTo(heroRef.current,{
                        // opacity:0,
                        height:"100svh",
                    },{
                            // opacity:1,
                            height:"75svh",
                            duration:1.2,
                            delay: isLoaded ? 1.2 : 1.9+1.2,
                            ease:"Power3.easeInOut",
                            onComplete: onComplete,
                    })
                },

                "(max-width: 768px)": function() {
                    timeline.current.fromTo(heroRef.current,{
                        // opacity:0,
                        height:"100svh",
                    },{
                            // opacity:1,
                            height:"60svh",
                            duration:1.2,
                            delay: isLoaded ? 1.2 : 1.9+1.2,
                            ease:"Power3.easeInOut",
                            onComplete: onComplete,
                    })
                }
            });
			return ()=>{
				timeline.current.kill();
			}
    }, [])

  return (
    <main ref={heroRef} className="w-full hero hero-banner" style={{overflow:"hidden", position:"relative", height:"75svh"}}>
        <>
        <div className='w-screen' style={{height:"100svh", position:"absolute", top:"0", left:"0", opacity:"0.15"}}>
            <Canvas dpr={1} 
                camera={{ fov: 75, near: 0.1, far: 130, position: [0, 0, 10] }}
                gl={{ antialias: false,  }}
                // flat
                linear
                // frameloop='demand'
                performance={{
                    current: 0.05,
                    min: 0.01,
                    max: 0.05,
                    debounce: 200
                }}
                >
                {/* <AdaptiveDpr pixelated /> */}
                {/* <AdaptivePixelRatio /> */}
                <Suspense fallback={null}>
                    {colorsStart.map((color,index)=>{
                        // console.log(color, 0.25 + (0.25 * index))
                        return <LowShaderPlane key={index} positionMesh={[0,0,0]} colorStart={color} colorEnd={colorsEnd[index]} positionBlob={new Vector2(Math.random()* 0.5 + 0.25, Math.random()* 0.5 + 0.25)} scale={1.5} opacity={1.0} timeFactor={0.05+(index+1)*0.05} keyBlob={index} initGsapAnimation={()=>{}} />
                    })}
                </Suspense>
            </Canvas>
        </div>

        
        <div className="flex flex-col justify-end w-full h-full px-10 py-20 text-black" style={{position:"absolute", top:"0", left:"0", backgroundColor:"#00000000"}}>
            {/* <div style={{borderTop:"1px solid black", width:"15ch"}}></div> */}
            
            <div>
                <h1 className="mt-8 title">{data.title}</h1>
            </div>
            {/* <div>
                <p className="mt-8" style={{fontSize:"32px", lineHeight:"1.2", fontWeight:"500", maxWidth:"25ch"}}>{data.subtitle}</p>
            </div>
            <div>
                <p style={{fontSize:"26px", lineHeight:"1.2", fontWeight:"400", maxWidth:"25ch"}}>{data.description}</p>
            </div> */}
        </div>
        </>
    </main>
  )
}

export default HeroGradient