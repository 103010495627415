import React from 'react'
import CustomLink from './custom-link'
import NextImage from './image'
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { getGradientColors } from '../../utils/colors';

const ProjectCard = ({event, first, last, isFilterSection = false}) => {
    const ref = React.useRef();
    const timeline = React.useRef();
    React.useEffect(()=>{
        const img = ref.current.querySelector(".event-card-image-wrapper")
        const title = ref.current.querySelector("h3")
        const text = ref.current.querySelector("p")

        timeline.current = gsap.timeline({
          scrollTrigger: {
            trigger: ref.current,
            start: "top 75%"
          }
        })


        timeline.current.fromTo(img, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          duration: 1.2,
          ease:"Power3.easeOut"
        },"<")


        timeline.current.fromTo(title, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          duration: 1.2,
          delay: 0.1,
          ease:"Power3.easeOut"
        },"<")


        timeline.current.fromTo(text, {
          opacity:0,
          y:"20px"
        },
        {
          opacity: 1,
          y:0,
          duration: 1.2,
          delay: 0.15,
          ease:"Power3.easeOut"
        },"<")
    },[])
    // console.log(event);
  return (
    <div className={`mb-12 project-card-wrapper ${isFilterSection ? "" : "h-full md:mb-0"}`} ref={ref}>
      <div className={`h-full event-card project ${isFilterSection ? "is-filter-section" : "md:px-5"} ${last ? "last-project" : ""}`} >
        <CustomLink link={{url: `/projects/${event.slug}` }} hasHoverInteraction={true} hovertext={`plus`}>
            <div className="event-card-image-wrapper" style={{position:"relative"}}>
                <div className="w-full" style={{borderRadius:"5px", overflow:"hidden"}}>
                    <div style={{
                        position:"relative",
                        width: "100%",
                        paddingTop: "59%",
                    }}>
                        <NextImage media={event.image} style={{position:"absolute", top:"0", objectFit:"cover", width:"100%", height:"100%", aspectRatio:"878/521", borderRadius:"0px"}}/>
                    </div>
                </div>
                <div style={{position:"absolute", top:"0", left:"0", zIndex:"1"}}>
                    <div className="px-4 mt-4 mb-4">
                      <div className="flex flex-wrap items-center justify-start">
                          {event.fields.map((field, index) => {
                            let colors = getGradientColors()[field.slug]
                            return (
                              <div key={index} 
                                  className="flex flex-wrap items-center justify-center text-center md:w-auto text-md md:text-sm button dark outline-cm always-active md:mr-2 " 
                                  style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}
                                  // onMouseEnter={(e) => { changeColor(index); }} 
                                  // onMouseLeave={(e) => { changeColor(-1); }}
                              >{field.tag}</div>
                            )
                          })}
                        </div>
                        {/* {event.fields.map((field, index)=>{
                            return <span className="mr-2" key={index} style={{fontFamily:"Larish", fontWeight:"600", fontSize:"13px", lineHeight:"24px", color:"white", border:"1px solid white", borderRadius:"22px", width:"50px", display:"inline-block", textAlign:"center"}}>{field.tag}</span>
                        })} */}
                    </div>
                </div>
            </div>
            <div className='px-0'>
                <h3 className="mt-5 mb-2 text-base font-bold">{event.title}</h3>
                <p className="text-base md:text-base" style={{ maxWidth:"65ch"}}>{event.description}</p>
            </div>
        </CustomLink>
      </div>
    </div>
  )
}

export default ProjectCard