import React from 'react';
import NextImage from '../elements/image';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const ImageGrid = ({ data }) => {
    
    return (
        <div className="pb-10 md:pb-20" >
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                    {data?.galleryItems.map((image, index) => (
                    <div key={index} className="w-full h-full p-5">
                        <NextImage media={image.image} style={{objectFit:"cover", objectPosition:"center", width:"100%", height:"100%"}}/>
                    </div>
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    )
}

export default ImageGrid;