import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import React from "react"
import Divider from "../elements/divider"
import NextImage from "../elements/image"
import GradientBackground from "../gradient/GradientBackground";
import GradientBalls from "../gradient/GradientBalls";
import { Color } from 'three';
import CustomLink from "../elements/custom-link";
import Markdown from "react-markdown"
import { getGradientColors } from "../../utils/colors";

const FieldsAbout = ({ data, fields, pageContext }) => {
  const ballMaterialRef = React.useRef();
  const positionElementRef = React.useRef();
  const timeline = React.useRef();
  const timeline2 = React.useRef();
  const firstRender = React.useRef(true);
  const colorsStart = fields.map((field) => new Color(getGradientColors()[field.slug].color1).convertLinearToSRGB().getHex())
  const colorsEnd = fields.map((field) => new Color(getGradientColors()[field.slug].color2).convertLinearToSRGB().getHex())
  const colorStart = new Color(pageContext.color1).convertLinearToSRGB().getHex()
  const colorEnd = new Color(pageContext.color2).convertLinearToSRGB().getHex()

  const [count, setCount] = React.useState(1)
  const animationRef = React.useRef(null);
  const animColorStart = React.useRef(colorStart);
  const animColorEnd = React.useRef(colorEnd);
  // console.log("FieldsAbout", data)

  const changeColor = (index) => {
    // console.log("mouseEnter/leave", index)
    // setColor1(colorsStart[index])
    // setColor2(colorsEnd[index])


    if(index > -1){
      animColorStart.current = colorsStart[index];
      animColorEnd.current = colorsEnd[index];

      animationRef.current = (ref, startColor, endColor) => {
        let newStartColor = new Color(startColor)
        let newEndColor = new Color(endColor)
        // console.log("update")
  
        gsap.to(ref.current.colorStart,
        {
            r: newStartColor.r,
            g: newStartColor.g,
            b: newStartColor.b,
            duration: 0.5
        })
  
        gsap.to(ref.current.colorEnd,
        {
            r: newEndColor.r,
            g: newEndColor.g,
            b: newEndColor.b,
            duration: 0.5,
            onComplete:()=>{
              // console.log("hello there end animation")
            }
        }, "<")
      }
    }else{
      animColorStart.current = colorStart;
      animColorEnd.current = colorEnd;

      animationRef.current = (ref, startColor, endColor) => {
        let newStartColor = new Color(startColor)
        let newEndColor = new Color(endColor)
        // console.log("update")
  
        gsap.to(ref.current.colorStart,
        {
            r: newStartColor.r,
            g: newStartColor.g,
            b: newStartColor.b,
            duration: 0.3
        })
  
        gsap.to(ref.current.colorEnd,
        {
            r: newEndColor.r,
            g: newEndColor.g,
            b: newEndColor.b,
            duration: 0.3,
            onComplete:()=>{
              // console.log("hello there end animation")
            }
        }, "<")
      }
    }

    setCount(count++)
    setCount(count++)
  }

  React.useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger)
    // changeColor(-1)

    // timeline.current = gsap.timeline({
    //   // yes, we can add it to an entire timeline!
    //   scrollTrigger: {
    //     id:"fieldScrollTrigger",
    //     trigger: ".fields-section",
    //     pin: true,   // pin the trigger element while active
    //     start: "top top", // when the top of the trigger hits the top of the viewport
    //     end: "400% bottom", // end after scrolling 500px beyond the start
    //     scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //     pinSpacing:true,
    //     markers:true,
    //     // snap: {
    //     //   snapTo: "labels", // snap to the closest label in the timeline
    //     //   duration: {min: 0.2, max: 0.7}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
    //     //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
    //     //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
    //     // },
    //     // onSnapComplete: () => console.log(timeline.current.currentLabel())
    //   }
    // });

    timeline2.current = gsap.timeline({
      // yes, we can add it to an entire timeline!
      // scrollTrigger: {
      //   id:"fieldScrollTrigger2",
      //   trigger: ".fields-section",
      //   // pin: true,   // pin the trigger element while active
      //   start: "top center", // when the top of the trigger hits the top of the viewport
      //   end: "bottom center", // end after scrolling 500px beyond the start
      //   scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      //   // pinSpacing: true,
      //   // markers:true,
      //   // snap: {
      //   //   snapTo: "labels", // snap to the closest label in the timeline
      //   //   duration: {min: 0.2, max: 0.7}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
      //   //   delay: 0.0, // wait 0.2 seconds from the last scroll event before doing the snapping
      //   //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
      //   // },
      //   // onSnapComplete: () => console.log(timeline.current.currentLabel())
      // }
    });


    // timeline2.current.to(positionElementRef.current.querySelector(".position-element"),{
    //   y: "50%",
    // })
    // gsap.set(positionElementRef.current.querySelector(".position-element"),{
    //   y: positionElementRef.current.querySelector(".field-element").offsetTop,
    // })
    // let positionElements = positionElementRef.current.querySelectorAll(".field-element");
    // positionElements.forEach((element, index) => {
    //   // var style = element.querySelector(".title").getComputedStyle() || window.getComputedStyle(element.querySelector(".title"));
    //   gsap.set(element,{
    //     opacity: 0.3,
    //   }, `field-element-${index}`)
    //   var style = window.getComputedStyle(element.querySelector(".title"));

    //   timeline2.current.addLabel(`field-element-${index}`, index).to(positionElementRef.current.querySelector(".position-element"),{
    //     y: element.offsetTop,
    //     height: element.querySelector(".title").clientHeight * 1.8,
    //     ease: "Power2.easeInOut",
    //   }, `field-element-${index}`)

    //   timeline2.current.to(element,{
    //     opacity: 1.0,
    //     ease: "Power2.easeInOut",
    //   }, `field-element-${index}`)
    //   if(index > 0){
    //     timeline2.current.to(positionElements[index-1],{
    //       opacity: 0.3,
    //       ease: "Power2.easeInOut",
    //     }, `field-element-${index}`)
    //   }
    // });

    // if(firstRender.current){
    //   firstRender.current = false
    // }
    
      
    // })
    // myTween.current.to(ballMaterialRef3.current, {
    //   scale: 10,
    //   ease: "Power3.easeInOut",
    //   duration: 0.1,
      
    // })
    return () => {
      // ScrollTrigger.getById("fieldScrollTrigger").kill(true);
      timeline2.current.kill();
      // ScrollTrigger.getById("fieldScrollTrigger2").kill(true);
      // timeline.current.kill();
      // console.log("killing pin")
    }

  },[])


  return (
    <div className="container fields-section">
      <div className="w-full">
        {/* <Divider />
        <h2 className="my-4 font-bold">{data.title}</h2> */}
        <div className="flex flex-col-reverse w-full md:grid md:grid-cols-2" style={{position:"relative", background:"transparent"}}>
          <div className="w-full h-full">
            <div className="w-full h-full py-40 pt-20 md:pt-40">
                <div className="mx-auto" style={{ position:"sticky", top:"37.5%", height:"25svh", width:"25svh"}}>
                  <GradientBalls colorsStart={[colorStart]} colorsEnd={[colorEnd]} positionsBlob={[0.5,0.5]} scale={1.5} circleScale={1.5} timeline={timeline2} disableScrollAnimation={count} runAnimationInstead={animationRef.current} animColorStart={animColorStart.current} animColorEnd={animColorEnd.current}/>
                </div>
            </div>
          </div>
          <div className="w-full pt-20 col-1 md:col-2">
            {data.element.map((element, index)=>{
              return (
                <div className="mb-20 md:mb-40" key={index}>
                  <Divider />
                  <h3 className="my-6 mt-2 font-bold" style={{fontSize:"20px"}}>{element.title}</h3>
                  <Markdown className="couto-mixto-markdown">{element.description}</Markdown>
                  {element.hasFields ? (
                    <div className="flex flex-wrap items-center justify-center px-10 mt-20 md:justify-start md:px-0">
                      {fields.map((field, index) => {
                        let colors = getGradientColors()[field.slug]
                        return (
                          <div key={index} 
                              className="flex flex-wrap items-center justify-center w-full my-5 text-center md:w-auto text-md md:text-sm button dark outline-cm md:mr-8 " 
                              style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}
                              onMouseEnter={(e) => { changeColor(index); }} 
                              onMouseLeave={(e) => { changeColor(-1); }}
                          >
                            <CustomLink link={{url: "/" + field.slug, id: field.id, text: field.name}}>
                              {field.name}
                            </CustomLink>
                          </div>
                        )
                      })}
                    </div>
                  ): ""}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FieldsAbout
