import React, {Suspense} from 'react'
import NextImage from '../elements/image'
import { Canvas } from '@react-three/fiber'


import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import LowShaderPlane from '../gradient/LowShaderPlane'
import { Color, Vector2 } from 'three';
import { PageTransitionContext } from '../page-transition';
import {useRouter} from 'next/router';
import { AdaptiveDpr } from '@react-three/drei';
import { getBannerGradientColors, getGradientColors } from '../../utils/colors';

const HeroBanner = ({ data, pageContext, isInitialLoad }) => {
	
    const router = useRouter()
    const { addToTimeline, isLoaded, playAnimation } = React.useContext(PageTransitionContext);
    const [counter, setCounter] = React.useState(0);
    // console.log(data)
    const leftRef = React.useRef();
    const rightRef = React.useRef();
    const heroRef = React.useRef();
    const timeline = React.useRef();
    const colors = getBannerGradientColors();
    // console.log(colors)

    // const colorsStart = data.colors.map((color) => new Color(color.colorStart).convertLinearToSRGB().getHex())
    // const colorsEnd = data.colors.map((color) => new Color(color.colorEnd).convertLinearToSRGB().getHex())

    const colorsStart = colors.map((color) => new Color(color).convertLinearToSRGB().getHex())
    const colorsEnd = colors.map((color) => new Color(color).convertLinearToSRGB().getHex())

    // var mediaQueries = [
    //     // { id: "xs", media: "(max-width: 640px)" },
    //     // { id: "s", media: "(min-width: 640px) and (max-width: 768px)" },
    //     // { id: "md", media: "(min-width: 768px) and (max-width: 1440px)" },
    //     // { id: "lg", media: "(min-width: 1440px) and (max-width: 1660px)" },
    //     // { id: "xl", media: "(min-width: 1660px)" }
    //     { id: "md", media: "(min-width: 768px) },
    //     { id: "lg", media: "(min-width: 1440px) and (max-width: 1660px)" },
    //   ];


    // setTimeout(() => {
    //     ScrollTrigger.refresh();
    //     ScrollTrigger.update();
    //     console.log("scrolltrigger refresh!");
    // }, 5000);

    const onComplete = () => {


        // FIX FOR FIRST LOAD, IDK WHY THE PLAIN REFRESH DOESN'T WORK ____ TODO: LOOK INTO THIS
        if (typeof(Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents'); 
            evt.initUIEvent('resize', true, false, window, 0); 
            window.dispatchEvent(evt);
        }
        ScrollTrigger.refresh();
        ScrollTrigger.update();
        isLoaded = true;
        // console.log("Refreshing triggers")
    }

    // React.useLayoutEffect(()=>{
    //     console.log(gsap.context())
    // },[])

    React.useEffect(()=>{
			gsap.registerPlugin(ScrollTrigger);
			// console.log("testing", playAnimation);
			if(timeline.current){
				timeline.current.kill();
			}
			timeline.current = gsap.timeline()
            const pathArray = router.asPath.split("/");
            ScrollTrigger.matchMedia({

                // large
                "(min-width: 769px)": function() {
                    // setup animations and ScrollTriggers for screens 960px wide or greater...
                    // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.

                    timeline.current.set(heroRef.current,{
                        height:"100svh",
                    })

                    timeline.current.fromTo(leftRef.current ,{
                        y:"100svh"
                    },{
                            y: 0,
                            duration: 1.3,
                            delay: isLoaded ? 0.0 : 1.9,
                            ease:"Power3.easeOut"
                    })

                    timeline.current.fromTo(rightRef.current ,{
                        y:"-100svh"
                    },{
                            y: 0,
                            duration: 1.3,
                            ease:"Power3.easeOut"
                    }, "<")

                    timeline.current.to(heroRef.current,{
                            // delay:1.5,
                            height:"75svh",
                            duration:1.2,
                            // delay:1.0,
                            ease:"Power3.easeInOut",
                            onComplete: onComplete,
                    })
                },
                // small
                "(max-width: 768px)": function() {
                    // The ScrollTriggers created inside these functions are segregated and get
                    // reverted/killed when the media query doesn't match anymore. 

                    timeline.current.set(heroRef.current,{
                        height:"100svh",
                    })

                    timeline.current.fromTo(leftRef.current ,{
                        x:"100vw"
                    },{
                            x: 0,
                            duration: 1.3,
                            delay: isLoaded ? 0.1 : 1.9,
                            ease:"Power3.easeOut"
                    })

                    timeline.current.fromTo(rightRef.current ,{
                        x:"-100vw"
                    },{
                            x: 0,
                            duration: 1.3,
                            ease:"Power3.easeOut",
                            onComplete: onComplete,
                    }, "<")

                    // timeline.current.to(heroRef.current,{
                    //         // delay:1.5,
                    //         height:"75vh",
                    //         duration:1.2,
                    //         // delay:1.0,
                    //         ease:"Power3.easeInOut",
                    //         onComplete: onComplete,
                    // })
                },
            });
			// }
            
            // setTimeout(() => {

            //     ScrollTrigger.update();
            //     ScrollTrigger.refresh(true);
            //     ScrollTrigger.update();
            //     console.log("scrolltrigger refresh!");
            // }, 5000);
			// addToTimeline(timeline.current);
            // ScrollTrigger.refresh();
			return ()=>{
				timeline.current.kill();
			}
    }, [])

  return (
    <main ref={heroRef} className="w-full hero hero-banner" style={{overflow:"hidden", position:"relative", height:"75svh"}}>
        <div className="grid h-full md:grid-cols-2">
            <div ref={leftRef} className="order-2 h-full m-0 grid-row-2 md:grid-row-1 md:order-1" style={{width:"100%", order:"2"}}>
                <div className="w-full h-full" style={{position:"relative"}}>
                    <div className="w-full h-full" style={{position:"absolute", top:"0", left:"0", background: pageContext.colorFooter}}>
                    </div>
                    <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div>
                    <div className="flex flex-col justify-end w-full h-full px-10 py-10 text-black md:py-20" style={{position:"absolute", top:"0", left:"0", backgroundColor: data.titleRight ? "#00000022" : "transparent"}}>
                        <div style={{borderTop:"1px solid black", width:"15ch"}}></div>
                        {!data.titleRight ? (
                            <div>
                                <h1 className="mt-8 hero-title-right">{data.title}</h1>
                            </div>
                        ) : ""}
                        <div>
                            <p className="mt-8 hero-subtitle-left">{data.subtitle}</p>
                        </div>
                        <div>
                            <p className="hero-text-left" >{data.description}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={rightRef} className="order-1 h-full m-0 grid-row-2 md:grid-row-1 md:order-2" style={{width:"100%"}}>
                <div className="w-full h-full" style={{position:"relative"}}>
                    <div className="w-full h-full" style={{position:"absolute", top:"0", left:"0"}}>
                        {data.image ? 
                            <NextImage media={data.image} style={{objectFit:"cover", objectPosition:"left", width:"100%", height:"100%"}}/>
                        : ""}
                    </div>
                    <div className="flex flex-col items-start justify-end w-full h-full px-10 py-20 text-white md:px-20" style={{position:"absolute", top:"0", left:"0", backgroundColor: data.image ? (data.titleRight ? "#00000022" : "transparent") : "transparent"}}>
                        <div></div>
                        <div className="text-center">
                            {data.titleRight ? (
                                <h1 className="hero-big-title">{data.title}</h1>
                            ) : ""}
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  )
}

export default HeroBanner