import React from 'react'
import NextImage from '../elements/image'

const ImageBanner = ({data}) => {
  return (
    <div className='relative w-full mb-40' style={{maxHeight:"66svh", background:"#e5e5e5", overflow:"hidden"}}>
        <NextImage media={data.image} style={{objectFit:"cover", objectPosition:"center", width:"100%", height:"100%", mixBlendMode:"multiply"}}/>
    </div>
  )
}

export default ImageBanner