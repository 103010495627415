import React from 'react'
import Divider from '../elements/divider'
import Markdown from "react-markdown"

const TextLeft = ({data}) => {
  return (
    <div className="container my-20">
        <Divider />
        <div className="pr-10 text-left-section-element">
            <h3 className="my-6 mt-2 font-bold" style={{fontSize:"20px"}}>{data.title}</h3>
            <Markdown className="couto-mixto-markdown">{data.description}</Markdown>
        </div>
    </div>
  )
}

export default TextLeft