import React from 'react'
import NextImage from '../elements/image'

const ImageFull = ({data}) => {
  return (
    <div className='container my-20' style={{maxHeight:"66svh", overflow:"hidden"}}>
        <NextImage media={data.image} style={{objectFit:"cover", objectPosition:"center", width:"100%", height:"100%", mixBlendMode:"multiply"}}/>
    </div>
  )
}

export default ImageFull