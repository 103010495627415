import React from 'react'
import CustomLink from './custom-link'
import NextImage from './image'
import moment from 'moment';
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { getGradientColors } from '../../utils/colors';

const EventCard = ({event, first}) => {
    const ref = React.useRef();
    React.useEffect(()=>{
        const img = ref.current.querySelector(".event-card-image-wrapper")
        const title = ref.current.querySelector("h3")
        const text = ref.current.querySelectorAll("p")
    
    
        gsap.fromTo(img, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          scrollTrigger: {
            trigger: img,
            start: "top 75%",
            // markers:true,
          },
          duration: 1.2,
          ease:"Power3.easeOut"
        },"<")
    
    
        gsap.fromTo(title, {
          opacity:0,
          y:"20px"
        },
        {
          opacity:1,
          y:0,
          duration: 1.2,
          scrollTrigger: {
            trigger: title,
            start: "top 75%",
            // markers:true,
          },
          ease:"Power3.easeOut"
        },"<")
    
    
        gsap.fromTo(text, {
          opacity:0,
          y:"20px"
        },
        {
          opacity: 1,
          y:0,
          duration: 1.2,
          stagger: 0.3,
          scrollTrigger: {
            trigger: text,
            start: "top 75%",
            // markers:true,
          },
          ease:"Power3.easeOut"
        },"<")
    },[])
  return (
    <div className='h-full mb-12 border-t-0 border-b-0 md:mb-0 event-card md:px-5 md:pb-10' ref={ref}>

      <CustomLink link={{url: `/events/${event.slug}` }} hasHoverInteraction={true} hovertext={`plus`}>
        <div className="event-card-image-wrapper" style={{position:"relative"}}>
            <div className="w-full" style={{borderRadius:"5px", overflow:"hidden"}}>
                <div style={{
                    position:"relative",
                    width: "100%",
                    paddingTop: "102%",
                }}>
                    <NextImage media={event.image} style={{position:"absolute",top:"0",left:"0", objectFit:"cover", width:"100%", height:"100%", aspectRatio:"420/429", borderRadius:"5px"}}/>
                </div>
            </div>
            <div style={{position:"absolute", top:"0", left:"0", zIndex:"1"}}>
                <div className="px-4 mt-4 mb-4">
                  <div className="flex flex-wrap items-center justify-start">
                      {event.fields.map((field, index) => {
                            let colors = getGradientColors()[field.slug] 
                        return (
                          <div key={index} 
                              className="flex flex-wrap items-center justify-center text-center md:w-auto text-md md:text-sm button dark outline-cm always-active md:mr-2 " 
                              style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}
                              // onMouseEnter={(e) => { changeColor(index); }} 
                              // onMouseLeave={(e) => { changeColor(-1); }}
                          >{field.tag}</div>
                        )
                      })}
                    </div>
                    {/* {event.fields.map((field, index)=>{
                        return <span className="mr-2" key={index} style={{fontFamily:"Larish", fontWeight:"600", fontSize:"13px", lineHeight:"24px", color:"white", border:"1px solid white", borderRadius:"22px", width:"50px", display:"inline-block", textAlign:"center"}}>{field.tag}</span>
                    })} */}
                </div>
            </div>
        </div>
        <div className='px-0'>
            <p className='p-0 m-0 mt-4 ' style={{fontSize:"10px", color:"#7C7C7C"}}>{moment(event.date,"YYYY-MM-DD").format("DD.MM.YY")}</p>
            <h3 className="mt-4 mb-2 text-base font-bold md:mb-2">{event.title}</h3>
            <p className="text-base md:text-base" style={{ maxWidth:"65ch"}}>{event.description}</p>
            {/* <CustomLink link={{url: `/events/${event.slug}` }}><span className="block link" style={{cursor:"pointer", color:"#7C7C7C", fontSize:"14px", marginTop:"1.5rem"}}>Ver Mais</span></CustomLink> */}
        </div>
      </CustomLink>
    </div>
  )
}

export default EventCard