import React, { useEffect, useRef, useState, forwardRef, useLayoutEffect, createRef, Suspense } from 'react'
import { Canvas, useFrame, useThree, useControls } from '@react-three/fiber'
import { LowBlobMaterial } from './LowBlobMaterial'
import * as THREE from 'three'
// import { Sphere, OrthographicCamera, PresentationControls, MeshWobbleMaterial, MeshDistortMaterial, MeshReflectorMaterial, Sparkles, Stars, Stage, Bounds, OrbitControls, AdaptiveDpr } from "@react-three/drei";
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import { Color, Vector2, Vector3 } from 'three';

// import { useSpring, animated } from '@react-spring/three'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";


const LowShaderPlane = ({colorStart="orange", colorEnd = "lightgrey", positionBlob, scale, positionMesh, initGsapAnimation, keyBlob, opacity, timeFactor })=> {
  const ref = useRef()
  const meshRef = useRef()


  const {x, y} = {x: positionBlob.x, y: positionBlob.y};
  const { width, height } = useThree((state) => state.viewport )

  const color1 = new Color(colorStart)
  const color2 = new Color(colorEnd)
  useFrame((state, delta) => {
    ref.current.time += delta;
    // console.log(colorStart)
    // ref.current.x += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
    // ref.current.y += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
    // meshRef.current.rotation.z += delta
    // materialRef.current.scale = scale;
  })

  useEffect(()=>{
    // console.log("add animation")
    // console.log(positionBlob)
    initGsapAnimation(ref, keyBlob);
  },[opacity])

  useEffect(()=>{
    // console.log("colorStart",colorStart)
    // console.log("colorEnd",colorEnd)
    // console.log("plane render")
  })

  // const onUpdate = (self) => {
  //   console.log(ref.current?.colorStart)
  //   console.log("update mesh")
  // }

  return (
    <mesh ref={meshRef} position={positionMesh} scale={[width, height, 1]}>
      <planeBufferGeometry args={[1, 1, 1]} />
      {/* We use the materials module 🔑 to allow HMR replace */}
      {/* <meshBasicMaterial color={new Color(colorStart)}/> */}
      <lowBlobMaterial ref={ref} key={keyBlob} colorStart={color1} colorEnd={color2} x={x} y={y} positionBlob={positionBlob} scale={scale} opacityFactor={opacity} timeFactor={timeFactor} transparent clampMin={0.0}/>
    </mesh>
  )
};

LowShaderPlane.displayName="LowShaderPlane"
export default LowShaderPlane




// const  ShaderPlane = ({colorStart="orange", colorEnd = "lightgrey", positionBlob, scale, positionMesh, initGsapAnimation, keyBlob, opacity, timeFactor })=> {
//   const ref = useRef()
//   const meshRef = useRef()


//   const {x, y} = {x: positionBlob.x, y: positionBlob.y};
//   const { width, height } = useThree((state) => state.viewport )

//   const color1 = new Color(colorStart)
//   const color2 = new Color(colorEnd)

//   // This shader is from Bruno Simons Threejs-Journey: https://threejs-journey.xyz
//   const noiseFunction = `
//   vec3 mod289(vec3 x)
//   {
//     return x - floor(x * (1.0 / 289.0)) * 289.0;
//   }

//   vec4 mod289(vec4 x)
//   {
//     return x - floor(x * (1.0 / 289.0)) * 289.0;
//   }

//   vec4 permute(vec4 x)
//   {
//     return mod289(((x*34.0)+1.0)*x);
//   }

//   vec4 taylorInvSqrt(vec4 r)
//   {
//     return 1.79284291400159 - 0.85373472095314 * r;
//   }

//   vec3 fade(vec3 t) {
//     return t*t*t*(t*(t*6.0-15.0)+10.0);
//   }

//   // Classic Perlin noise
//   float cnoise(vec3 P)
//   {
//     vec3 Pi0 = floor(P); // Integer part for indexing
//     vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
//     Pi0 = mod289(Pi0);
//     Pi1 = mod289(Pi1);
//     vec3 Pf0 = fract(P); // Fractional part for interpolation
//     vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
//     vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
//     vec4 iy = vec4(Pi0.yy, Pi1.yy);
//     vec4 iz0 = Pi0.zzzz;
//     vec4 iz1 = Pi1.zzzz;

//     vec4 ixy = permute(permute(ix) + iy);
//     vec4 ixy0 = permute(ixy + iz0);
//     vec4 ixy1 = permute(ixy + iz1);

//     vec4 gx0 = ixy0 * (1.0 / 7.0);
//     vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
//     gx0 = fract(gx0);
//     vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
//     vec4 sz0 = step(gz0, vec4(0.0));
//     gx0 -= sz0 * (step(0.0, gx0) - 0.5);
//     gy0 -= sz0 * (step(0.0, gy0) - 0.5);

//     vec4 gx1 = ixy1 * (1.0 / 7.0);
//     vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
//     gx1 = fract(gx1);
//     vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
//     vec4 sz1 = step(gz1, vec4(0.0));
//     gx1 -= sz1 * (step(0.0, gx1) - 0.5);
//     gy1 -= sz1 * (step(0.0, gy1) - 0.5);

//     vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
//     vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
//     vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
//     vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
//     vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
//     vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
//     vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
//     vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

//     vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
//     g000 *= norm0.x;
//     g010 *= norm0.y;
//     g100 *= norm0.z;
//     g110 *= norm0.w;
//     vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
//     g001 *= norm1.x;
//     g011 *= norm1.y;
//     g101 *= norm1.z;
//     g111 *= norm1.w;

//     float n000 = dot(g000, Pf0);
//     float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
//     float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
//     float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
//     float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
//     float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
//     float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
//     float n111 = dot(g111, Pf1);

//     vec3 fade_xyz = fade(Pf0);
//     vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
//     vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
//     float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
//     return 2.2 * n_xyz;
//   }`;
//   const vertexShader = `
//   varying vec2 vUv;
//   void main() {
//     vec4 modelPosition = modelMatrix * vec4(position, 1.0);
//     vec4 viewPosition = viewMatrix * modelPosition;
//     vec4 projectionPosition = projectionMatrix * viewPosition;
//     gl_Position = projectionPosition;
//     vUv = uv;
//   }`;

//   const fragmentShader = `

//     ${noiseFunction}

//     vec3 sRGBToLinear( in vec3 value ) {
//       return vec3( mix( pow( value.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), value.rgb * 0.0773993808, vec3( lessThanEqual( value.rgb, vec3( 0.04045 ) ) ) ));
//     }

//     uniform float time;
//     uniform float scale;
//     uniform vec3 colorStart;
//     uniform vec3 colorEnd;
//     uniform vec2 positionBlob;
//     uniform float x;
//     uniform float y;
//     uniform float clampMin;
//     uniform float opacityFactor;
//     uniform float timeFactor;
//     varying vec2 vUv;
//     void main() {
//       vec2 positionBlobTemp = vec2(x,y);
//       // vec2 positionBlobTemp = vec2(0.5,0.5);
//       // vec2 positionBlobTemp2 = vec2(-0.5,-0.5);
//       vec2 displacedUv = vec2(vUv.x + cnoise(vec3(vUv.x * 2.0, vUv.y * 1.0, time * timeFactor)), vUv.y + cnoise(vec3(vUv.x * 1.0, vUv.y * 2.0, time * timeFactor)));
//       float strength = cnoise(vec3(displacedUv.x * 0.1, displacedUv.y * 0.1, cos(time * 0.1) * 0.1 + 0.5));
//       float outerGlow = distance(displacedUv, positionBlobTemp) * scale ;
//       float outerGlow2 = distance(displacedUv, positionBlobTemp) * 0.5;
//       outerGlow += step(500.5, outerGlow);
//       outerGlow2 += step(5.5, outerGlow2);
//       strength += outerGlow - outerGlow2;
//       strength += step(100.0, strength);
//       strength = clamp(strength, 0.0, 1.0);
//       vec3 color = mix(colorStart, colorEnd, outerGlow);
//       vec3 color2 = mix(colorEnd, colorStart, outerGlow2);
//       vec3 finalColor = mix(color, color2, strength);
//       // gl_FragColor = vec4(color, (1.0-strength));

//       gl_FragColor = vec4(finalColor, (opacityFactor-strength));
//     }`;

//   useFrame((state, delta) => {
//     // console.log(ref.current.uniforms.time.value);

//     // console.log(ref.current.uniforms.time.value + delta);
//     if(opacity && opacity > 0.0){
//       ref.current.uniforms.time = { value:  ref.current.uniforms.time.value + delta};
//     }
//     // meshRef.current.material.time += delta;
//     // console.log(colorStart)
//     // ref.current.x += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
//     // ref.current.y += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
//     // meshRef.current.rotation.z += delta
//     // materialRef.current.scale = scale;
//   })

//   useEffect(()=>{
//     console.log("add animation")
//     // console.log(positionBlob)
//     if(opacity > 0){
//     initGsapAnimation(ref, keyBlob);
//     }
//   },[opacity])

//   useEffect(()=>{
//     // console.log("colorStart",colorStart)
//     // console.log("colorEnd",colorEnd)
//     // console.log("plane render")
//   })

//   // const onUpdate = (self) => {
//   //   console.log(ref.current?.colorStart)
//   //   console.log("update mesh")
//   // }

//   const data = {
//       uniforms: {
//         // Ka: { value: new THREE.Vector3(1, 1, 1) },
//         // Kd: { value: new THREE.Vector3(1, 1, 1) },
//         // Ks: { value: new THREE.Vector3(1, 1, 1) },
//         // LightIntensity: { value: new THREE.Vector4(0.5, 0.5, 0.5, 1.0) },
//         // LightPosition: { value: new THREE.Vector4(0.0, 2000.0, 0.0, 1.0) },
//         // Shininess: { value: 200.0 },
//         time: { value: 0 },
//         scale: { value: scale },
//         colorStart: { value: color1 },
//         colorEnd: { value: color2 },
//         x: {value: x},
//         y: {value: y},
//         clampMin: {value: 0.0},
//         positionBlob: { value: positionBlob },
//         opacityFactor: { value: opacity },
//         timeFactor: { value: timeFactor },
//       },
//       fragmentShader: fragmentShader,
//       vertexShader: vertexShader
//     }

//   return (
//     <mesh ref={meshRef} position={positionMesh} scale={[width, height, 1]}>
//       <planeBufferGeometry args={[1, 1, 1]} />
//       {/* We use the materials module 🔑 to allow HMR replace */}
//       {/* <meshBasicMaterial color={new Color(colorStart)}/> */}
//       <blobMaterial ref={ref} key={keyBlob} colorStart={color1} colorEnd={color2} x={x} y={y} positionBlob={positionBlob} scale={scale} opacityFactor={opacity} timeFactor={timeFactor} transparent clampMin={0.0}/>
//       {/* <shaderMaterial ref={ref} 
//       uniforms={{
//         time: { value: 0 },
//         scale: { value: scale },
//         colorStart: { value: color1 },
//         colorEnd: { value: color2 },
//         x: {value: x},
//         y: {value: y},
//         clampMin: {value: 0.0},
//         positionBlob: { value: positionBlob },
//         opacityFactor: { value: opacity },
//         timeFactor: { value: timeFactor },
//       }}
//       transparent
//       fragmentShader={fragmentShader}
//       vertexShader={vertexShader} /> */}
//     </mesh>
//   )
// };

// ShaderPlane.displayName="ShaderPlane"
// export default ShaderPlane