import NextImage from "../elements/image"
import Divider from "../elements/divider"
import EventCard from "../elements/event-card"
import React from "react"
import { getEventsPaginated } from "../../utils/api"

import ScrollTrigger from "gsap/dist/ScrollTrigger"
import gsap from "gsap"
import CustomLink from "../elements/custom-link"


const EventsHome = ({ data, pageContext }) => {

  const [gridData, setGridData] = React.useState(null);
    const getData = async () => {

      // res = await getProjectsPaginated("", pageContext.locale, false, tag, currentPage, 10);
      const res = await getEventsPaginated("rank:asc", pageContext.locale, false, "all", 1, 4);
      ScrollTrigger.refresh();
      ScrollTrigger.update();
      // res = await getArticlesPaginated("", pageContext.locale, false, tag, currentPage, 10);
      setGridData(res)
    }
    React.useEffect(()=>{
        // getData().then(()=>{
        //   ScrollTrigger.refresh();
        //   ScrollTrigger.update();
        // });;
    },[])
  // console.log("EventsHome", data)
  return (
    <div className="container my-10">
      <Divider />
      <div className="flex justify-between my-4">
        <h2 className="font-bold">{data.title}</h2>
        <CustomLink link={{ url:"/events", newTab:false}}>
          <div className='p-1 px-10 text-center text-black hover:text-white hover:bg-black' style={{
              maxWidth:"263px",
              border: "1px solid #000000",
              borderRadius: "22px",
              fontSize:"14px",
              transition:"0.3s ease-out"
          }}>Ver Todos</div>
        </CustomLink>
      </div>
      <div className="grid grid-cols-2 gap-5 py-12 align-top md:grid-cols-4 md:gap-0 margin-fix-cards">
        {pageContext && pageContext.events && pageContext.events.slice(0,4).map((event, index) => (
          <EventCard event={event} key={index}/>
        ))}
      </div>
    </div>
  )
}

export default EventsHome
