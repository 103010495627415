import React from 'react'
import Divider from '../elements/divider'
import Markdown from "react-markdown"

const TitleLeft = ({data}) => {
  return (
    <div className="container mb-10">
        <Divider />
        <div className="pr-10 text-left-section-element">
            <h3 className="font-bold mt-2 my-6" style={{fontSize:"20px"}}>{data.title}</h3>
        </div>
    </div>
  )
}

export default TitleLeft