import NextImage from "../elements/image"
import Divider from "../elements/divider"
import ArticleCard from "../elements/article-card"
import React from "react"
import { getArticlesPaginated } from "../../utils/api"

import ScrollTrigger from "gsap/dist/ScrollTrigger"
import gsap from "gsap"
import CustomLink from "../elements/custom-link"

const NewsHome = ({ data, fields, pageContext }) => {

  const [gridData, setGridData] = React.useState(null);

  const getData = async () => {
    const res = await getArticlesPaginated("", pageContext.locale, false, "all", 1, 4);
    ScrollTrigger.refresh();
    ScrollTrigger.update();
    setGridData(res)
  }

  React.useEffect(()=>{
    // gsap.registerPlugin(ScrollTrigger);
    // getData().then(()=>{
    //   ScrollTrigger.refresh();
    //   ScrollTrigger.update();
    // });
  },[])

  return (
    <div className="container my-10">
      <Divider />
      <div className="flex justify-between my-4">
        <h2 className="font-bold">{data.title}</h2>
        <CustomLink link={{ url:"/articles", newTab:false}}>
          <div className='p-1 px-10 text-center text-black hover:text-white hover:bg-black' style={{
              maxWidth:"263px",
              border: "1px solid #000000",
              borderRadius: "22px",
              fontSize:"14px",
              transition:"0.3s ease-out"
          }}>Ver Todos</div>
        </CustomLink>
      </div>
      <div className="grid grid-cols-2 gap-5 py-12 align-top md:grid-cols-4 md:gap-0 margin-fix-cards">
        {pageContext && pageContext.articles && pageContext.articles.slice(0,4).map((article, index) => (
          <ArticleCard event={article} key={index}/>
        ))}
      </div>
    </div>
  )
}

export default NewsHome
