import React from 'react'

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 57.236 32" style={{width:"100%", height:"100%"}}>
      <path d="M15,1.037h1.037V0H15Zm0,52.787-.7.762.7.65.7-.65ZM13.9,2.074H15V0H13.9Zm.062-1.037V53.824h2.074V1.037ZM0,41.389l14.3,13.2L15.7,53.062l-14.3-13.2L0,41.389Zm15.7,13.2L30,41.389l-1.406-1.523-14.3,13.2L15.7,54.586Z" transform="translate(0 30) rotate(-90)" fill='currentColor'/>
    </svg>
  )
}

export default Arrow

