import { useRouter } from "next/router"
import Hero from "/components/sections/hero"
import HeroBanner from "/components/sections/hero-banner"
import LargeVideo from "/components/sections/large-video"
import FeatureColumnsGroup from "/components/sections/feature-columns-group"
import FeatureRowsGroup from "/components/sections/feature-rows-group"
import BottomActions from "/components/sections/bottom-actions"
import TestimonialsGroup from "/components/sections/testimonials-group"
import RichText from "./sections/rich-text"
// import Pricing from "./sections/pricing"
import LeadForm from "./sections/lead-form"
import ProjectsHome from "./sections/projects-home"
import NewsHome from "./sections/news-home"
import EventsHome from "./sections/events-home"
import FieldsHome from "./sections/fields-home"
import FieldsAbout from "./sections/fields-about"
import ColumnText from "./sections/column-text"
import ImageBanner from "./sections/image-banner"
import TextLeft from "./sections/text-left"
import WordBanner from "./sections/word-banner"
import Services from "./sections/services"
import FilterSection from "./sections/filter-section"
import TeamSection from "./sections/team-section"
import EventInfo from "./sections/event-info"
import TitleLeft from "./sections/title-left"
import ImageFull from "./sections/image-full"
import HeroGradient from "./sections/hero-gradient"
import ImageGrid from "./sections/image-grid"

// Map Strapi sections to section components
const sectionComponents = {
  "sections.hero": Hero,
  "sections.hero-banner": HeroBanner,
  "sections.hero-gradient": HeroGradient,
  "sections.large-video": LargeVideo,
  "sections.feature-columns-group": FeatureColumnsGroup,
  "sections.feature-rows-group": FeatureRowsGroup,
  "sections.bottom-actions": BottomActions,
  "sections.testimonials-group": TestimonialsGroup,
  "sections.rich-text": RichText,
  // "sections.pricing": Pricing,
  "sections.lead-form": LeadForm,
  "sections.projects-home": ProjectsHome,
  "sections.news-home": NewsHome,
  "sections.events-home": EventsHome,
  "sections.fields-home": FieldsHome,
  "sections.fields-about": FieldsAbout,
  "sections.column-text": ColumnText,
  "sections.image-banner": ImageBanner,
  "sections.image-full": ImageFull,
  "sections.text-left": TextLeft,
  "sections.title-left": TitleLeft,
  "sections.word-banner": WordBanner,
  "sections.services": Services,
  "sections.filter-section": FilterSection,
  "sections.team-section": TeamSection,
  "sections.event-info": EventInfo,
  "images.gallery": ImageGrid,
}

// Display a section individually
const Section = ({ sectionData, fields, pageContext }) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component]

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} fields={fields} pageContext={pageContext} />
}

const PreviewModeBanner = () => {
  const router = useRouter()
  const exitURL = `/api/exit-preview?redirect=${encodeURIComponent(
    router.asPath
  )}`

  return (
    <div className="py-4 font-semibold tracking-wide text-red-100 uppercase bg-red-600">
      <div className="container">
        Preview mode is on.{" "}
        <a
          className="underline"
          href={`/api/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  )
}

// Display the list of sections
const Sections = ({ sections, preview, fields, pageContext }) => {
  return (
    <>
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections.map((section) => (
        <Section
          sectionData={section}
          fields={fields}
          pageContext={pageContext}
          key={`${section.__component}${section.id}`}
        />
      ))}
    </>
  )
}

export default Sections
