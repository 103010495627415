import NextImage from "../elements/image"
import Divider from "../elements/divider"
import ArticleCard from "../elements/article-card"
import ButtonLink from "../elements/button-link"
import Markdown from "react-markdown"
import { getButtonAppearance } from "/utils/button"
import React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const ColumnText = ({ data }) => {
  // console.log("ColumnText", data)
  const ref = React.useRef();
  const timeline = React.useRef();

  React.useEffect(()=>{
    // timeline.current = gsap.timeline({
    //   scrollTrigger: {
    //     id:"background_gradient_scrolltrigger",
    //     trigger: ref.current,
    //     start: 'top bottom',
    //     // end: `${vh(150)} top`,
    //     // scrub: 1,
    //     // markers:true,
    //     // start: start,
    //     // end: '90%'
    //   }
    // })
    const h2 = ref.current.querySelector("h2")
    const title = ref.current.querySelector(".title")
    const text = ref.current.querySelector(".couto-mixto-markdown")


    gsap.fromTo(h2, {
      opacity:0,
      y:"20px"
    },
    {
      opacity:1,
      y:0,
      scrollTrigger: {
        trigger: h2,
        start: "top 75%"
      },
      duration: 1.2,
      ease:"Power3.easeOut"
    },"<")


    gsap.fromTo(title, {
      opacity:0,
      y:"20px"
    },
    {
      opacity:1,
      y:0,
      duration: 1.2,
      scrollTrigger: {
        trigger: title,
        start: "top 75%"
      },
      ease:"Power3.easeOut"
    },"<")


    gsap.fromTo(text.querySelectorAll("p"), {
      opacity:0,
      y:"20px"
    },
    {
      opacity: 1,
      y:0,
      stagger: 0.3,
      duration: 1.2,
      scrollTrigger: {
        trigger: text,
        start: "top 75%"
      },
      ease:"Power3.easeOut"
    },"<")

  }, [])

  return (
    <div className="container my-20 column-text-section" ref={ref}>
      <Divider />
      <h2 className="font-bold mt-2 my-4">{data.sectionTitle}</h2>
      <div className="md:grid md:grid-cols-2 gap-0 align-top py-12">
        <div>
          <h3 className="title" style={{fontSize:"26px", maxWidth:"25ch"}}>{data.title}</h3>
          {data.button ? 
          <ButtonLink
            button={data.button}
            appearance={getButtonAppearance(data.button.type, "light")}
            compact
          />
          : ""}
        </div>
        <div className="pt-12 md:pt-0 md:pl-12">
          <Markdown className="couto-mixto-markdown">{data.description}</Markdown>
        </div>
      </div>
    </div>
  )
}

export default ColumnText
