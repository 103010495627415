import React from 'react'
import Divider from '../elements/divider'
import Markdown from "react-markdown"
import moment from 'moment';
import momentPT from 'moment/locale/pt';
import CustomLink from '../elements/custom-link';
import { getGradientColors } from '../../utils/colors';

const EventInfo = ({children, pageContext}) => {
    const timeHelper = pageContext.time?.split(".")[0].split(":");
    React.useEffect(() => {
        if( isPt() ){
            moment.locale("pt", momentPT)
        }else{
            moment.locale("en")
        }
    }, [])
    const isPt = () => {
        return pageContext.locale == "pt-PT" || pageContext.locale == "pt";
    }
  return (
    <div className="relative w-full mt-20 mb-20">
        <div className='static h-full m-auto lg:m-0 lg:absolute event-info-floater'>
            <div className="static px-5 mb-40 md:px-10 lg:px-10 md:grid md:gap-10 md:grid-cols-3 lg:block lg:sticky md:mb-20 lg:mb-0" style={{top:"7rem"}}>
                { pageContext.fields ? (
                    <div className='mb-10 event-info-floater-item'>
                        <Divider/>
                        <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                            <h3 className="mt-2 mb-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{isPt() ? "Serviços" : "Services"}</h3>

                            <div className="flex flex-wrap items-center justify-start">
                                {pageContext.fields.map((field, index) => {
                                  let colors = getGradientColors()[field.slug]
                                  return (
                                    <div key={index} 
                                        className="flex flex-wrap items-center justify-center mr-1 text-center md:w-auto text-md md:text-sm button dark outline-cm always-active md:mr-2 " 
                                        style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}
                                        // onMouseEnter={(e) => { changeColor(index); }} 
                                        // onMouseLeave={(e) => { changeColor(-1); }}
                                    >{field.tag}</div>
                                  )
                                })}
                            </div>
                            {/* <p className='p-0 m-0' style={{fontSize:"18px", fontWeight:"500"}}>
                              {pageContext.fields.map((field, index)=>{
                                if(index == 0)
                                  return field.name
                                else
                                  return " . " + field.name
                              })}
                            </p> */}
                        </div>
                    </div>
                ) : ""}
                { pageContext.price || pageContext.price == "0" ? (
                    <div className='mb-10 event-info-floater-item'>
                        <Divider/>
                        <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                            <h3 className="mt-2 mb-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{isPt() ? "Preço" : "Price"}</h3>
                            <p className='p-0 m-0' style={{fontSize:"18px", fontWeight:"500"}}>{pageContext.price == "0.0" ? (isPt() ? "Entrada Livre" : "Free Entrance") : pageContext.price + "€"}</p>
                        </div>
                    </div>
                ) : ""}
                
                {pageContext.date ? (
                    <div className='mb-10 event-info-floater-item'>
                        <Divider/>
                        <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                            <h3 className="mt-2 mb-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{isPt() ? "Data" : "Date"}</h3>
                            {/* <p style={{fontSize:"20px", lineHeight:"1.3", fontWeight:"300", maxWidth:"25ch", color: pageContext.colorFooter }}>{moment(pageContext.date).format("LL")}</p> */}
                            <p className='p-0 m-0' style={{fontSize:"18px", fontWeight:"500"}}>{ moment(pageContext.date).format("LL") }</p>
                        </div>
                    </div>
                ): ""}

                {timeHelper ? (
                    <div className='mb-20 event-info-floater-item'>
                        <Divider/>
                        <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                            <h3 className="mt-2 mb-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{isPt() ? "Horário" : "Schedule"}</h3>
                            <p className='p-0 m-0' style={{fontSize:"18px", fontWeight:"500"}}>{timeHelper[0]}h{timeHelper[1]}</p>
                        </div>
                    </div>
                ) : ""}


                {pageContext.location ? (
                    <div className='mb-20 event-info-floater-item'>
                        <Divider/>
                        <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                            <h3 className="mt-2 mb-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>Local</h3>
                            <p className='p-0 m-0' style={{fontSize:"18px", fontWeight:"500"}}>{pageContext.location}</p>
                        </div>
                    </div>
                ) : ""}

                { pageContext.ticketsURL ? (
                    <div className='mb-10' style={{alignSelf:"center"}}>
                        <CustomLink link={{ url:"https://google.pt", newTab:true}}>
                            <div className='p-2 text-center text-white' style={{
                                maxWidth:"263px",
                                background: "#000000 0% 0% no-repeat padding-box",
                                border: "1px solid #000000",
                                borderRadius: "22px",
                            }}>{isPt() ? "Comprar Bilhetes" : "Buy Tickets"}</div>
                        </CustomLink>
                    </div>
                ) : ""}
            </div>
        </div>
        <div className="w-full mb-40 md:px-5 event-info-sections">
            {children}
        </div>
    </div>
  )
}

export default EventInfo