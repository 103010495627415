import React from 'react'

const WordBanner = ({data}) => {
    // console.log(data)
  return (
    <div className='word-banner-section' style={{minHeight:"100svh"}}>
        <div className='h-full' style={{minHeight:"inherit"}}>
            <div className='flex flex-col items-center justify-center h-full' style={{minHeight:"inherit"}}>
                {data.words.map((word, index)=>{
                    var middle = Math.floor(word.text.length / 2);
                    
                    var s1 = word.text.substr(0, middle);
                    var s2 = word.text.substr(middle);
                    return (
                        <div className="mb-6" key={index}>
                            <span className="text-right align-self-center">{s1}</span>
                            <span className="text-left align-self-center">{s2}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
  )
}

export default WordBanner