import NextImage from "../elements/image"
import Divider from "../elements/divider"

import React from "react"
import { getEventsPaginated } from "../../utils/api"
import TeamCard from "../elements/team-card"

const TeamSection = ({ data, pageContext }) => {

  const [gridData, setGridData] = React.useState(null);


    const onClickFilterHander = (tag) => {
        if(!tag){
            setActiveFilter("all");
            getData("all");
        }else{
            setActiveFilter(tag);
            getData(tag);
        }

    }
    const getData = async () => {

      // res = await getProjectsPaginated("", pageContext.locale, false, tag, currentPage, 10);
      const res = await getEventsPaginated("", pageContext.locale, false, "all", 1, 4);
      // res = await getArticlesPaginated("", pageContext.locale, false, tag, currentPage, 10);
      setGridData(res)
    }
    React.useEffect(()=>{
        getData();
    },[])

    const renderFillCards = () => {
      const rest = Array(4 - data.members.length % 4).fill(1);
      
      return rest.map((e, index)=>{
          return (
            <div key={index} className='event-card md:px-5 mb-12 border-t-0 border-b-0 w-full'>
              <div className="w-full h-full p-4 flex justify-center items-center">
                  <div style={{height:"70px", width:"70px", borderRadius:"50%", background:`${pageContext[`color${(index)%2 + 1}`]}`}}></div>
              </div>
            </div>
          )
        })

    }
  // console.log("EventsHome", data)
  return (
    <div className="container my-10">
      <Divider />
      <h2 className="font-bold mt-2 my-4">{data.title}</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-0 align-top py-12 margin-fix-cards">
        {data.members.map((event, index) => (
          <TeamCard event={event} key={index} backgroundColor={pageContext.colorFooter}/>
        ))}
        {renderFillCards()}
      </div>
    </div>
  )
}

export default TeamSection
